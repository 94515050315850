<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'ChatroomIndex',
              }"
              >聊天室管理</b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: 'ChatroomList',
              }"
              >聊天室列表</b-breadcrumb-item
            >
            <b-breadcrumb-item active>
              {{ isEdit ? "聊天室編輯" : "新增聊天室" }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">{{ title }}</h4>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <section class="row mb-8">
          <div class="col-12 col-xl-8">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="標題"
              label-for="title"
            >
              <b-form-input
                id="title"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="state.form.title"
                :state="v$.form.title.$error ? false : null"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.title.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              v-if="isEdit"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="聊天室來源"
              label-for="source_type"
            >
              <b-form-input
                id="source_type"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="state.form.source_type"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              v-if="isEdit"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="來源ID"
              label-for="source_id"
            >
              <b-form-input
                id="source_id"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                v-model="state.form.source_id"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="是否啟用"
              label-for="is_enabled"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="state.form.is_enabled"
                name="is_enabled"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="是否允許加入"
              label-for="allow_join"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="state.form.allow_join"
                name="allow_join"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="類型"
              label-for="type"
            >
              <b-form-select
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="state.form.type"
                name="type"
                :options="typeOptions"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="狀態"
              label-for="status"
            >
              <b-form-input
                id="status"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="state.form.status"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="起始日"
              label-for="start_at"
            >
              <b-form-input
                id="start_at"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="date"
                v-model="state.form.start_at"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="結束日"
              label-for="end_at"
            >
              <b-form-input
                id="end_at"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="date"
                v-model="state.form.end_at"
              ></b-form-input>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel"
          >取消</b-button
        >
        <b-overlay
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button variant="success" @click="handleSubmit">
            {{ isEdit ? "確認修改" : "確認新增" }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import chatroomApi from "../../../apis/chatroomManagement";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import moment from "moment";

export default {
  setup() {
    const state = reactive({
      form: {
        title: null,
        source_type: null,
        source_id: null,
        type: 0,
        is_enabled: 1,
        allow_join: 0,
        status: null,
        start_at: null,
        end_at: null,
      },
    });
    const rules = {
      form: {
        title: { required },
      },
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      isLoading: false,
      isSubmmiting: false,
      form: {
        title: null,
        source_type: null,
        source_id: null,
        type: 0,
        is_enabled: 1,
        allow_join: 0,
        status: null,
        start_at: null,
        end_at: null,
      },
      typeOptions: [
        { value: 0, text: "WALTILY" },
        { value: 1, text: "LINE_OA" },
      ],
    };
  },
  validations() {
    return {
      form: {
        title: { required },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    chatroomId() {
      return this.$route.params.chatroomId;
    },
    isEdit() {
      return this.$route.name === "ChatroomEdit";
    },
    title() {
      switch (true) {
        case this.isEdit:
          return "聊天室編輯";
        default:
          return "新增聊天室";
      }
    },
  },
  mounted() {
    if (!this.canView()) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({ name: "ChatroomList" });
        });
    } else if (this.chatroomId) {
      this.fetchChatroom();
    }
  },
  methods: {
    async fetchChatroom() {
      try {
        this.isLoading = true;
        const { data } = await chatroomApi.getChatroom(this.chatroomId);
        console.info("data", data);
        const {
          title,
          source_type,
          source_id,
          type,
          is_enabled,
          allow_join,
          status,
          start_at,
          end_at,
        } = data.data;

        if (this.isShow || this.isEdit) {
          this.form = {
            ...this.form,
          };
          this.state.form = {
            title,
            source_type,
            source_id,
            type,
            is_enabled: is_enabled ? 1 : 0,
            allow_join: allow_join ? 1 : 0,
            status,
            start_at: moment(start_at).isValid()
              ? moment(start_at).format("YYYY-MM-DD")
              : null,
            end_at: moment(end_at).isValid()
              ? moment(end_at).format("YYYY-MM-DD")
              : null,
          };
        }
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;
      if (this.isEdit) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    },
    async handleUpdate() {
      try {
        this.isSubmmiting = true;
        console.info("form", this.getFormData());
        await chatroomApi.updateChatroom(this.chatroomId, this.getFormData());
        this.showSuccessPopup("修改成功");
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.isSubmmiting = false;
    },
    async handleCreate() {
      try {
        this.isSubmmiting = true;

        await chatroomApi.createChatroom(this.getFormData());
        this.showSuccessPopup("新增成功");
      } catch (error) {
        this.showErrorMessage(error);
      }
      this.isSubmmiting = false;
    },
    handleCancel() {
      this.$router.push({ name: "ChatroomList" });
    },
    showSuccessPopup(message) {
      this.$swal
        .fire({
          type: "success",
          text: message,
        })
        .then(() => {
          this.$router.push({ name: "ChatroomList" });
        });
    },
    showErrorMessage(error) {
      const { status, data } = error.response;

      if (status && status === 422 && data.message) {
        const html = Object.values(data.message)
          .map((m) => m[0])
          .join("<br/>");
        this.$swal.fire({
          type: "error",
          html,
        });
      }
    },
    canView() {
      return (
        (this.isEdit && this.checkPermission([consts.CHATROOM_LIST_MODIFY])) ||
        (!this.isEdit && this.checkPermission([consts.CHATROOM_LIST_CREATE]))
      );
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    getFormData() {
      const form = { ...this.form, ...this.state.form };

      Object.keys(form).forEach(
        (key) => form[key] === null && delete form[key]
      );

      return form;
    },
  },
};
</script>
